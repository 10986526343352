//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VueCookieLaw',
  props: {
    buttonText: {
      type: String,
      default: 'Got it!'
    },
    buttonDecline: {
      type: Boolean,
      default: false
    },
    buttonDeclineText: {
      type: String,
      default: 'Decline'
    },
    buttonLink: {
      type: [String, Object],
      required: false
    },
    buttonLinkText: {
      type: String,
      default: 'More info'
    },
    buttonLinkNewTab: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: 'This website uses cookies to ensure you get the best experience on our website.'
    },
    theme: {
      type: String,
      default: 'base'
    },
    /**
     * Cookie Container position
     * bottom, top
     * @type {Object}
     */
    position: {
      type: String,
      default: 'bottom'
    },
    /**
     * Transition name has following possibilities
     * slideFromBottom
     * slideFromTop
     * fade
     * @type {Object}
     */
    transitionName: {
      type: String,
      default: 'slideFromBottom'
    },
    buttonClass: {
      type: String,
      default: 'Cookie__button'
    },
    buttonDeclineClass: {
      type: String,
      default: 'Cookie__button--decline'
    },
    storageName: {
      type: String,
      default: 'cookie:accepted'
    },
    cookieOptions: {
      type: Object,
      default: () => {},
      required: false
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    containerPosition () {
      return `Cookie--${this.position}`
    },
    cookieTheme () {
      return `Cookie--${this.theme}`
    },
    externalButtonLink () {
      return typeof this.buttonLink === 'string' && this.buttonLink.length
    },
    internalButtonLink () {
      return typeof this.buttonLink === 'object' && this.buttonLink != null && Object.keys(this.buttonLink).length
    },
    target () {
      return this.buttonLinkNewTab ? '_blank' : '_self'
    }
  },
  created () {
    if (!this.getVisited()) {
      this.isOpen = true
    }
  },
  mounted () {
    if (this.isAccepted()) {
      this.$emit('accept')
    }
  },
  methods: {
    setVisited () {
      if (process.browser) {
        localStorage.setItem(this.storageName, true)
      }
    },
    setAccepted () {
      if (process.browser) {
        localStorage.setItem(this.storageName, true)
      }
    },
    setDeclined () {
      if (process.browser) {
        localStorage.setItem(this.storageName, false)
      }
    },
    getVisited () {
      let visited = null
      if (process.browser) {
        visited = localStorage.getItem(this.storageName)

        if (typeof visited === 'string') {
          visited = JSON.parse(visited)
        }
      }
      return !(visited === null || visited === undefined)
    },
    isAccepted () {
      let accepted = false

      if (process.browser) {
        accepted = localStorage.getItem(this.storageName)

        if (typeof accepted === 'string') {
          accepted = JSON.parse(accepted)
        }
      }

      return accepted
    },
    accept () {
      this.setVisited()
      this.setAccepted()
      this.isOpen = false
      this.$emit('accept')
    },
    close () {
      this.isOpen = false
      this.$emit('close')
    },
    decline () {
      this.setVisited()
      this.setDeclined()
      this.isOpen = false
      this.$emit('decline')
    },
    revoke () {
      if (process.browser) {
        localStorage.removeItem(this.storageName)
        this.isOpen = true
        this.$emit('revoke')
      }
    },
    open () {
      if (!this.getVisited()) {
        this.isOpen = true
      }
    }
  }
}

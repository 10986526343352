export const AccordionControl = () => import('../../components/AccordionControl.vue' /* webpackChunkName: "components/accordion-control" */).then(c => wrapFunctional(c.default || c))
export const AccordionPage = () => import('../../components/AccordionPage.vue' /* webpackChunkName: "components/accordion-page" */).then(c => wrapFunctional(c.default || c))
export const AjaxRequestError = () => import('../../components/AjaxRequestError.vue' /* webpackChunkName: "components/ajax-request-error" */).then(c => wrapFunctional(c.default || c))
export const AjaxRequestSuccess = () => import('../../components/AjaxRequestSuccess.vue' /* webpackChunkName: "components/ajax-request-success" */).then(c => wrapFunctional(c.default || c))
export const AnchorRouterLink = () => import('../../components/AnchorRouterLink.vue' /* webpackChunkName: "components/anchor-router-link" */).then(c => wrapFunctional(c.default || c))
export const BackToTopButton = () => import('../../components/BackToTopButton.vue' /* webpackChunkName: "components/back-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const CookieLaw = () => import('../../components/CookieLaw.vue' /* webpackChunkName: "components/cookie-law" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../components/Error.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MobileNavBarPageHeader = () => import('../../components/MobileNavBarPageHeader.vue' /* webpackChunkName: "components/mobile-nav-bar-page-header" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const ToolTip = () => import('../../components/ToolTip.vue' /* webpackChunkName: "components/tool-tip" */).then(c => wrapFunctional(c.default || c))
export const YouTubeVideo = () => import('../../components/YouTubeVideo.vue' /* webpackChunkName: "components/you-tube-video" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsColumnHeader = () => import('../../components/FilterResults/ColumnHeader.vue' /* webpackChunkName: "components/filter-results-column-header" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsDownloadButton = () => import('../../components/FilterResults/DownloadButton.vue' /* webpackChunkName: "components/filter-results-download-button" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsMobileColumnHeader = () => import('../../components/FilterResults/MobileColumnHeader.vue' /* webpackChunkName: "components/filter-results-mobile-column-header" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsMobileRow = () => import('../../components/FilterResults/MobileRow.vue' /* webpackChunkName: "components/filter-results-mobile-row" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsNotesButton = () => import('../../components/FilterResults/NotesButton.vue' /* webpackChunkName: "components/filter-results-notes-button" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsPackageCard = () => import('../../components/FilterResults/PackageCard.vue' /* webpackChunkName: "components/filter-results-package-card" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsPane = () => import('../../components/FilterResults/Pane.vue' /* webpackChunkName: "components/filter-results-pane" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsProductCard = () => import('../../components/FilterResults/ProductCard.vue' /* webpackChunkName: "components/filter-results-product-card" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsRow = () => import('../../components/FilterResults/Row.vue' /* webpackChunkName: "components/filter-results-row" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsTable = () => import('../../components/FilterResults/Table.vue' /* webpackChunkName: "components/filter-results-table" */).then(c => wrapFunctional(c.default || c))
export const FilterResultsUsefulLinks = () => import('../../components/FilterResults/UsefulLinks.vue' /* webpackChunkName: "components/filter-results-useful-links" */).then(c => wrapFunctional(c.default || c))
export const ContactDetails = () => import('../../components/contact/Details.vue' /* webpackChunkName: "components/contact-details" */).then(c => wrapFunctional(c.default || c))
export const DealerDesktop = () => import('../../components/dealer/Desktop.vue' /* webpackChunkName: "components/dealer-desktop" */).then(c => wrapFunctional(c.default || c))
export const DealerMobile = () => import('../../components/dealer/Mobile.vue' /* webpackChunkName: "components/dealer-mobile" */).then(c => wrapFunctional(c.default || c))
export const DealersAllStatesDesktop = () => import('../../components/dealers/AllStatesDesktop.vue' /* webpackChunkName: "components/dealers-all-states-desktop" */).then(c => wrapFunctional(c.default || c))
export const DealersAllStatesMobile = () => import('../../components/dealers/AllStatesMobile.vue' /* webpackChunkName: "components/dealers-all-states-mobile" */).then(c => wrapFunctional(c.default || c))
export const DealersDesktop = () => import('../../components/dealers/Desktop.vue' /* webpackChunkName: "components/dealers-desktop" */).then(c => wrapFunctional(c.default || c))
export const DealersMobile = () => import('../../components/dealers/Mobile.vue' /* webpackChunkName: "components/dealers-mobile" */).then(c => wrapFunctional(c.default || c))
export const DealersStateBanner = () => import('../../components/dealers/StateBanner.vue' /* webpackChunkName: "components/dealers-state-banner" */).then(c => wrapFunctional(c.default || c))
export const DocumentsCategory = () => import('../../components/documents/Category.vue' /* webpackChunkName: "components/documents-category" */).then(c => wrapFunctional(c.default || c))
export const DocumentsSpecial = () => import('../../components/documents/Special.vue' /* webpackChunkName: "components/documents-special" */).then(c => wrapFunctional(c.default || c))
export const FaqCard = () => import('../../components/faq/Card.vue' /* webpackChunkName: "components/faq-card" */).then(c => wrapFunctional(c.default || c))
export const HomeCarousel = () => import('../../components/home/Carousel.vue' /* webpackChunkName: "components/home-carousel" */).then(c => wrapFunctional(c.default || c))
export const HomeCarouselInner = () => import('../../components/home/CarouselInner.vue' /* webpackChunkName: "components/home-carousel-inner" */).then(c => wrapFunctional(c.default || c))
export const HomeDesktop = () => import('../../components/home/Desktop.vue' /* webpackChunkName: "components/home-desktop" */).then(c => wrapFunctional(c.default || c))
export const HomeDesktopFeaturedCard = () => import('../../components/home/DesktopFeaturedCard.vue' /* webpackChunkName: "components/home-desktop-featured-card" */).then(c => wrapFunctional(c.default || c))
export const HomeDesktopTileCard = () => import('../../components/home/DesktopTileCard.vue' /* webpackChunkName: "components/home-desktop-tile-card" */).then(c => wrapFunctional(c.default || c))
export const HomeMobile = () => import('../../components/home/Mobile.vue' /* webpackChunkName: "components/home-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomeMobileFeaturedCard = () => import('../../components/home/MobileFeaturedCard.vue' /* webpackChunkName: "components/home-mobile-featured-card" */).then(c => wrapFunctional(c.default || c))
export const HomeMobileTileCard = () => import('../../components/home/MobileTileCard.vue' /* webpackChunkName: "components/home-mobile-tile-card" */).then(c => wrapFunctional(c.default || c))
export const HomeTablet = () => import('../../components/home/Tablet.vue' /* webpackChunkName: "components/home-tablet" */).then(c => wrapFunctional(c.default || c))
export const HomeTabletFeaturedCard = () => import('../../components/home/TabletFeaturedCard.vue' /* webpackChunkName: "components/home-tablet-featured-card" */).then(c => wrapFunctional(c.default || c))
export const LinkButton = () => import('../../components/link/Button.vue' /* webpackChunkName: "components/link-button" */).then(c => wrapFunctional(c.default || c))
export const LinkImage = () => import('../../components/link/Image.vue' /* webpackChunkName: "components/link-image" */).then(c => wrapFunctional(c.default || c))
export const PackageCard = () => import('../../components/package/Card.vue' /* webpackChunkName: "components/package-card" */).then(c => wrapFunctional(c.default || c))
export const PackagesEcuPackage = () => import('../../components/packages/EcuPackage.vue' /* webpackChunkName: "components/packages-ecu-package" */).then(c => wrapFunctional(c.default || c))
export const PackagesTargetedRange = () => import('../../components/packages/TargetedRange.vue' /* webpackChunkName: "components/packages-targeted-range" */).then(c => wrapFunctional(c.default || c))
export const PageHintCarousel = () => import('../../components/page/HintCarousel.vue' /* webpackChunkName: "components/page-hint-carousel" */).then(c => wrapFunctional(c.default || c))
export const PageSuggestedLinks = () => import('../../components/page/SuggestedLinks.vue' /* webpackChunkName: "components/page-suggested-links" */).then(c => wrapFunctional(c.default || c))
export const ProductsCategoryCard = () => import('../../components/products/CategoryCard.vue' /* webpackChunkName: "components/products-category-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsChosenCategoryCard = () => import('../../components/products/ChosenCategoryCard.vue' /* webpackChunkName: "components/products-chosen-category-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsDesktop = () => import('../../components/products/Desktop.vue' /* webpackChunkName: "components/products-desktop" */).then(c => wrapFunctional(c.default || c))
export const ProductsMobile = () => import('../../components/products/Mobile.vue' /* webpackChunkName: "components/products-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductsMobileCategoryCard = () => import('../../components/products/MobileCategoryCard.vue' /* webpackChunkName: "components/products-mobile-category-card" */).then(c => wrapFunctional(c.default || c))
export const ProductsTablet = () => import('../../components/products/Tablet.vue' /* webpackChunkName: "components/products-tablet" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/Card.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCartItem = () => import('../../components/product/CartItem.vue' /* webpackChunkName: "components/product-cart-item" */).then(c => wrapFunctional(c.default || c))
export const ProductCompareItem = () => import('../../components/product/CompareItem.vue' /* webpackChunkName: "components/product-compare-item" */).then(c => wrapFunctional(c.default || c))
export const ProductLink = () => import('../../components/product/Link.vue' /* webpackChunkName: "components/product-link" */).then(c => wrapFunctional(c.default || c))
export const SearchPane = () => import('../../components/search/Pane.vue' /* webpackChunkName: "components/search-pane" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../components/search/Result.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))
export const ShowcaseCard = () => import('../../components/showcase/Card.vue' /* webpackChunkName: "components/showcase-card" */).then(c => wrapFunctional(c.default || c))
export const ShowcasesChosenCategoryCard = () => import('../../components/showcases/ChosenCategoryCard.vue' /* webpackChunkName: "components/showcases-chosen-category-card" */).then(c => wrapFunctional(c.default || c))
export const ShowcasesChosenCategoryCardInner = () => import('../../components/showcases/ChosenCategoryCardInner.vue' /* webpackChunkName: "components/showcases-chosen-category-card-inner" */).then(c => wrapFunctional(c.default || c))
export const VideosCard = () => import('../../components/videos/Card.vue' /* webpackChunkName: "components/videos-card" */).then(c => wrapFunctional(c.default || c))
export const VideosDesktop = () => import('../../components/videos/Desktop.vue' /* webpackChunkName: "components/videos-desktop" */).then(c => wrapFunctional(c.default || c))
export const VideosMobile = () => import('../../components/videos/Mobile.vue' /* webpackChunkName: "components/videos-mobile" */).then(c => wrapFunctional(c.default || c))
export const VideosTablet = () => import('../../components/videos/Tablet.vue' /* webpackChunkName: "components/videos-tablet" */).then(c => wrapFunctional(c.default || c))
export const WebinarsAccordionPage = () => import('../../components/webinars/AccordionPage.vue' /* webpackChunkName: "components/webinars-accordion-page" */).then(c => wrapFunctional(c.default || c))
export const PageColumnContent = () => import('../../components/page/column/Content.vue' /* webpackChunkName: "components/page-column-content" */).then(c => wrapFunctional(c.default || c))
export const PageColumnHorizontalRule = () => import('../../components/page/column/HorizontalRule.vue' /* webpackChunkName: "components/page-column-horizontal-rule" */).then(c => wrapFunctional(c.default || c))
export const PageColumnImage = () => import('../../components/page/column/Image.vue' /* webpackChunkName: "components/page-column-image" */).then(c => wrapFunctional(c.default || c))
export const PageColumnImageInner = () => import('../../components/page/column/ImageInner.vue' /* webpackChunkName: "components/page-column-image-inner" */).then(c => wrapFunctional(c.default || c))
export const PageColumnLinkToAnchor = () => import('../../components/page/column/LinkToAnchor.vue' /* webpackChunkName: "components/page-column-link-to-anchor" */).then(c => wrapFunctional(c.default || c))
export const PageColumnMultiLinkToAnchor = () => import('../../components/page/column/MultiLinkToAnchor.vue' /* webpackChunkName: "components/page-column-multi-link-to-anchor" */).then(c => wrapFunctional(c.default || c))
export const PageColumnNumberedList = () => import('../../components/page/column/NumberedList.vue' /* webpackChunkName: "components/page-column-numbered-list" */).then(c => wrapFunctional(c.default || c))
export const PageColumnNumberedListInner = () => import('../../components/page/column/NumberedListInner.vue' /* webpackChunkName: "components/page-column-numbered-list-inner" */).then(c => wrapFunctional(c.default || c))
export const PageColumnSocialMediaLinks = () => import('../../components/page/column/SocialMediaLinks.vue' /* webpackChunkName: "components/page-column-social-media-links" */).then(c => wrapFunctional(c.default || c))
export const PageColumnTextBlock = () => import('../../components/page/column/TextBlock.vue' /* webpackChunkName: "components/page-column-text-block" */).then(c => wrapFunctional(c.default || c))
export const PageColumnTextBlockInner = () => import('../../components/page/column/TextBlockInner.vue' /* webpackChunkName: "components/page-column-text-block-inner" */).then(c => wrapFunctional(c.default || c))
export const PageColumnTitle = () => import('../../components/page/column/Title.vue' /* webpackChunkName: "components/page-column-title" */).then(c => wrapFunctional(c.default || c))
export const PageDatasheetContent = () => import('../../components/page/datasheet/Content.vue' /* webpackChunkName: "components/page-datasheet-content" */).then(c => wrapFunctional(c.default || c))
export const PageDatasheetDownloadButton = () => import('../../components/page/datasheet/DownloadButton.vue' /* webpackChunkName: "components/page-datasheet-download-button" */).then(c => wrapFunctional(c.default || c))
export const PageMainAnchor = () => import('../../components/page/main/Anchor.vue' /* webpackChunkName: "components/page-main-anchor" */).then(c => wrapFunctional(c.default || c))
export const PageMainContent = () => import('../../components/page/main/Content.vue' /* webpackChunkName: "components/page-main-content" */).then(c => wrapFunctional(c.default || c))
export const PageMainHeaderImage = () => import('../../components/page/main/HeaderImage.vue' /* webpackChunkName: "components/page-main-header-image" */).then(c => wrapFunctional(c.default || c))
export const PageMainHeaderTextBlock = () => import('../../components/page/main/HeaderTextBlock.vue' /* webpackChunkName: "components/page-main-header-text-block" */).then(c => wrapFunctional(c.default || c))
export const PageMainHeaderTitle = () => import('../../components/page/main/HeaderTitle.vue' /* webpackChunkName: "components/page-main-header-title" */).then(c => wrapFunctional(c.default || c))
export const PageMainHorizontalRule = () => import('../../components/page/main/HorizontalRule.vue' /* webpackChunkName: "components/page-main-horizontal-rule" */).then(c => wrapFunctional(c.default || c))
export const PageMainImage = () => import('../../components/page/main/Image.vue' /* webpackChunkName: "components/page-main-image" */).then(c => wrapFunctional(c.default || c))
export const PageMainImageInner = () => import('../../components/page/main/ImageInner.vue' /* webpackChunkName: "components/page-main-image-inner" */).then(c => wrapFunctional(c.default || c))
export const PageMainInstagram = () => import('../../components/page/main/Instagram.vue' /* webpackChunkName: "components/page-main-instagram" */).then(c => wrapFunctional(c.default || c))
export const PageMainLinkToAnchor = () => import('../../components/page/main/LinkToAnchor.vue' /* webpackChunkName: "components/page-main-link-to-anchor" */).then(c => wrapFunctional(c.default || c))
export const PageMainLinkToPage = () => import('../../components/page/main/LinkToPage.vue' /* webpackChunkName: "components/page-main-link-to-page" */).then(c => wrapFunctional(c.default || c))
export const PageMainLinkToPageInner = () => import('../../components/page/main/LinkToPageInner.vue' /* webpackChunkName: "components/page-main-link-to-page-inner" */).then(c => wrapFunctional(c.default || c))
export const PageMainMoreButton = () => import('../../components/page/main/MoreButton.vue' /* webpackChunkName: "components/page-main-more-button" */).then(c => wrapFunctional(c.default || c))
export const PageMainPopBoxInner = () => import('../../components/page/main/PopBoxInner.vue' /* webpackChunkName: "components/page-main-pop-box-inner" */).then(c => wrapFunctional(c.default || c))
export const PageMainQuote = () => import('../../components/page/main/Quote.vue' /* webpackChunkName: "components/page-main-quote" */).then(c => wrapFunctional(c.default || c))
export const PageMainScalableImage = () => import('../../components/page/main/ScalableImage.vue' /* webpackChunkName: "components/page-main-scalable-image" */).then(c => wrapFunctional(c.default || c))
export const PageMainScalableImageInner = () => import('../../components/page/main/ScalableImageInner.vue' /* webpackChunkName: "components/page-main-scalable-image-inner" */).then(c => wrapFunctional(c.default || c))
export const PageMainTextBlock = () => import('../../components/page/main/TextBlock.vue' /* webpackChunkName: "components/page-main-text-block" */).then(c => wrapFunctional(c.default || c))
export const PageMainTextBlockAndBulletList = () => import('../../components/page/main/TextBlockAndBulletList.vue' /* webpackChunkName: "components/page-main-text-block-and-bullet-list" */).then(c => wrapFunctional(c.default || c))
export const PageMainTextBlockAndPopBox = () => import('../../components/page/main/TextBlockAndPopBox.vue' /* webpackChunkName: "components/page-main-text-block-and-pop-box" */).then(c => wrapFunctional(c.default || c))
export const PageMainTitle = () => import('../../components/page/main/Title.vue' /* webpackChunkName: "components/page-main-title" */).then(c => wrapFunctional(c.default || c))
export const PageMainYouTubeVideo = () => import('../../components/page/main/YouTubeVideo.vue' /* webpackChunkName: "components/page-main-you-tube-video" */).then(c => wrapFunctional(c.default || c))
export const PageSnapshotsCard = () => import('../../components/page/snapshots/Card.vue' /* webpackChunkName: "components/page-snapshots-card" */).then(c => wrapFunctional(c.default || c))
export const PageSnapshotsCardInner = () => import('../../components/page/snapshots/CardInner.vue' /* webpackChunkName: "components/page-snapshots-card-inner" */).then(c => wrapFunctional(c.default || c))
export const PageSnapshotsTable = () => import('../../components/page/snapshots/Table.vue' /* webpackChunkName: "components/page-snapshots-table" */).then(c => wrapFunctional(c.default || c))
export const PageSnapshotsTableHeader = () => import('../../components/page/snapshots/TableHeader.vue' /* webpackChunkName: "components/page-snapshots-table-header" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributeValueBoolean = () => import('../../components/product/AttributeValue/Boolean.vue' /* webpackChunkName: "components/product-attribute-value-boolean" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributeValueBooleanOrHTML = () => import('../../components/product/AttributeValue/BooleanOrHTML.vue' /* webpackChunkName: "components/product-attribute-value-boolean-or-h-t-m-l" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributeValueBooleanSubtext = () => import('../../components/product/AttributeValue/BooleanSubtext.vue' /* webpackChunkName: "components/product-attribute-value-boolean-subtext" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributeValueHTML = () => import('../../components/product/AttributeValue/HTML.vue' /* webpackChunkName: "components/product-attribute-value-h-t-m-l" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributeValueNumberOrNaN = () => import('../../components/product/AttributeValue/NumberOrNaN.vue' /* webpackChunkName: "components/product-attribute-value-number-or-na-n" */).then(c => wrapFunctional(c.default || c))
export const ProductAttributeValueTernary = () => import('../../components/product/AttributeValue/Ternary.vue' /* webpackChunkName: "components/product-attribute-value-ternary" */).then(c => wrapFunctional(c.default || c))
export const ProductDataLoggerAttributes = () => import('../../components/product/DataLogger/Attributes.vue' /* webpackChunkName: "components/product-data-logger-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductDataLoggerComparison = () => import('../../components/product/DataLogger/Comparison.vue' /* webpackChunkName: "components/product-data-logger-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductDisplayComparison = () => import('../../components/product/Display/Comparison.vue' /* webpackChunkName: "components/product-display-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductDisplayTable = () => import('../../components/product/Display/Table.vue' /* webpackChunkName: "components/product-display-table" */).then(c => wrapFunctional(c.default || c))
export const ProductDisplayLoggerAttributes = () => import('../../components/product/DisplayLogger/Attributes.vue' /* webpackChunkName: "components/product-display-logger-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductDisplayLoggerComparison = () => import('../../components/product/DisplayLogger/Comparison.vue' /* webpackChunkName: "components/product-display-logger-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductGoldBoxEcuAttributes = () => import('../../components/product/GoldBoxEcu/Attributes.vue' /* webpackChunkName: "components/product-gold-box-ecu-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductGoldBoxEcuComparison = () => import('../../components/product/GoldBoxEcu/Comparison.vue' /* webpackChunkName: "components/product-gold-box-ecu-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductGoldBoxEcuTable = () => import('../../components/product/GoldBoxEcu/Table.vue' /* webpackChunkName: "components/product-gold-box-ecu-table" */).then(c => wrapFunctional(c.default || c))
export const ProductLambdaComparison = () => import('../../components/product/Lambda/Comparison.vue' /* webpackChunkName: "components/product-lambda-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductLambdaTable = () => import('../../components/product/Lambda/Table.vue' /* webpackChunkName: "components/product-lambda-table" */).then(c => wrapFunctional(c.default || c))
export const ProductLoggerTable = () => import('../../components/product/Logger/Table.vue' /* webpackChunkName: "components/product-logger-table" */).then(c => wrapFunctional(c.default || c))
export const ProductM1EcuComparison = () => import('../../components/product/M1Ecu/Comparison.vue' /* webpackChunkName: "components/product-m1-ecu-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductM1EcuDiAttributes = () => import('../../components/product/M1Ecu/DiAttributes.vue' /* webpackChunkName: "components/product-m1-ecu-di-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductM1EcuPiAttributes = () => import('../../components/product/M1Ecu/PiAttributes.vue' /* webpackChunkName: "components/product-m1-ecu-pi-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductM1EcuTable = () => import('../../components/product/M1Ecu/Table.vue' /* webpackChunkName: "components/product-m1-ecu-table" */).then(c => wrapFunctional(c.default || c))
export const ProductPdmAttributes = () => import('../../components/product/Pdm/Attributes.vue' /* webpackChunkName: "components/product-pdm-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductPdmComparison = () => import('../../components/product/Pdm/Comparison.vue' /* webpackChunkName: "components/product-pdm-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductPdmTable = () => import('../../components/product/Pdm/Table.vue' /* webpackChunkName: "components/product-pdm-table" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      phrase: '',
      searchHistory: []
    }
  },
  props: {
    isExpanded: Boolean
  },
  methods: {
    ...mapMutations({
      setPhraseUpdateHistory: 'search/setPhraseUpdateHistory',
      setPhrase: 'search/setPhrase'
    }),
    onEnter () {
      if (this.phrase.trim() !== '') {
        this.setPhraseUpdateHistory(this.phrase)
        this.$router.push('/search')
      }
    },
    toggleHistory () {
      this.searchHistory = this.$store.getters['search/getHistory']
      if (this.searchHistory.length > 0) {
        this.$refs.mydropdown.classList.toggle('show')
      }
    },
    setSearchPhrase (phrase) {
      this.$refs.mydropdown.classList.toggle('show')
    },
    revisitHistory (phrase) {
      this.$refs.mydropdown.classList.toggle('show')
      this.phrase = phrase
      this.setPhrase(this.phrase) // don't update history
      this.$router.push('/search')
    }
  },
  watch: {
    isExpanded () {
      if (this.isExpanded) {
        this.$nextTick(() => this.$refs.search.focus())
      } else {
        this.phrase = ''
      }
    }
  }
}


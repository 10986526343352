export const state = () => ({
  items: []
})

export const mutations = {
  setItems (state, items) {
    // state.items = items
    state.items = []
  },
  add (state, item) {
    if (!state.items) {
      state.items = []
    }
    const record = state.items.find(i => i.id === item.id)

    if (!record) {
      state.items.push({
        ...item
      })
    }
    // this.$cookies.set('cart', state.items)

    if (process.browser) {
      localStorage.setItem('cart', JSON.stringify(state.items))
    }
  },
  remove (state, item) {
    const index = state.items.findIndex(i => i.id === item.id)
    state.items.splice(index, 1)
    // this.$cookies.set('cart', state.items)

    if (process.browser) {
      localStorage.setItem('cart', JSON.stringify(state.items))
    }
  },
  removeAll (state, item) {
    const index = state.items.findIndex(i => i.id === item.id)
    state.items.splice(index, 1)
    // this.$cookies.set('cart', state.items)
    if (process.browser) {
      localStorage.setItem('cart', JSON.stringify(state.items))
    }
  }
}

export const getters = {
  items: (state) => {
    return state.items
  },
  numberOfItems: (state) => {
    return state.items ? state.items.length : 0
  },
  canAdd: state => (item) => {
    const record = state.items ? state.items.find(i => i.id === item.id) : null
    return record == null
  },
  canRemove: state => (item) => {
    const record = state.items.find(i => i.id === item.id)
    return record.quantity > 1
  },
  isCartItem: state => (item) => {
    return state.items ? state.items.find(i => i.id === item.id) : false
  }
}

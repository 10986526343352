export const state = () => ({
  items: []
})

export const mutations = {
  setItems (state, items) {
    // state.items = items
    state.items = []
  },
  add (state, item) {
    if (!state.items) {
      state.items = []
    }
    const record = state.items.find(i => i.id === item.id)

    if (!record) {
      state.items.push({
        id: item.id,
        name: item.name,
        partNo: item.partNo,
        url: item.thumbnail.url,
        class: item.product_compare_class.name
      })
    }

    if (process.browser) {
      localStorage.setItem('compare_items', JSON.stringify(state.items))
    }
  },
  remove (state, item) {
    const index = state.items.findIndex(i => i.id === item.id)
    state.items.splice(index, 1)

    if (process.browser) {
      localStorage.setItem('compare_items', JSON.stringify(state.items))
    }
  }
}

export const getters = {
  items: (state) => {
    return state.items
  },
  numberOfItems: (state) => {
    return state.items ? state.items.length : 0
  },
  isCompareItem: state => (item) => {
    return state.items ? state.items.find(i => i.id === item.id) : false
  },
  canCompareItem: state => (item) => {
    if (!state.items || state.items.length === 0) {
      return true
    } else if (state.items.length < 3 && item.product_compare_class) {
      return (state.items[0].class === item.product_compare_class.name)
    } else {
      return false
    }
  }
}

export default {
  methods: {
    isExternalUrl (url) {
      return url.includes(':')
    },
    fullUrl (link) {
      if (link) {
        return this.isExternalUrl(link) ? link : this.$config.cmsURL + link
      } else {
        return null
      }
    },
    thumbnailImageUrl (image) { // max 208px
      if (!image || !image.data) {
        return null
      }
      if (!image.data.attributes.formats || !image.data.attributes.formats.thumbnail) {
        return this.$config.cmsURL + image.data.attributes.url
      }
      return this.$config.cmsURL + image.data.attributes.formats.thumbnail.url
    },
    smallImageUrl (image) { // max 500px
      if (!image || !image.data) {
        return null
      }
      if (!image.data.attributes.formats || !image.data.attributes.formats.small) {
        return this.$config.cmsURL + image.data.attributes.url
      }
      return this.$config.cmsURL + image.data.attributes.formats.small.url
    },
    mediumImageUrl (image) { // max 750px
      if (!image || !image.data) {
        return null
      }
      if (!image.data.attributes.formats || !image.data.attributes.formats.medium) {
        return this.$config.cmsURL + image.data.attributes.url
      }
      return this.$config.cmsURL + image.data.attributes.formats.medium.url
    },
    largeImageUrl (image) { // max 1000px
      if (!image || !image.data) {
        return null
      }
      if (!image.data.attributes.formats || !image.data.attributes.formats.large) {
        return this.$config.cmsURL + image.data.attributes.url
      }
      return this.$config.cmsURL + image.data.attributes.formats.large.url
    }
  }
}

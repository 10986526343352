export const state = () => ({
  store: {}
})

export const mutations = {
  setDownloads (state, downloads) {
    state.store = downloads
  },
  setCategory (state, category) {
    if (!state.store) {
      state.store = {}
    }
    state.store.category = category
    if (process.browser) {
      localStorage.setItem('downloads', JSON.stringify(state.store))
    }
  },
  setSubcategory (state, subcategory) {
    if (!state.store) {
      state.store = {}
    }
    state.store.subcategory = subcategory
    if (process.browser) {
      localStorage.setItem('downloads', JSON.stringify(state.store))
    }
  }
}

export const getters = {
  category: (state) => {
    return state.store ? state.store.category : null
  },
  subcategory: (state) => {
    return state.store ? state.store.subcategory : null
  }
}

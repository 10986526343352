//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data () {
    return {
      searchOpen: false,
      hamburgerOpen: false,
      email: '',
      password: '',
      error: false,
      navLhs: [
        {
          title: 'Products',
          to: { name: 'products' }
        },
        {
          title: 'Downloads',
          to: { name: 'downloads' }
        },
        {
          title: 'Training',
          to: { name: 'training' }
        }
      ],
      navRhs: [
        {
          title: 'FAQ',
          to: { name: 'faq' }
        },
        {
          title: 'Careers',
          to: { path: '/site/careers' }
        },
        {
          title: 'Dealers',
          to: { name: 'dealers' }
        }
      ],
      ignoredUrls: [
        '/site/information',
        '/site/careers'
      ]
    }
  },
  methods: {
    toggleSearch () {
      this.searchOpen = !this.searchOpen
    },
    closeSearch () {
      this.searchOpen = false
    },
    toggleHamburger () {
      this.$refs.hamburgerMenu.classList.toggle('show')
      const emailRef = this.$refs.email
      if (emailRef) {
        this.$nextTick(() => emailRef.focus())
      }
    },
    toggleHamburgerCloseSearch () {
      this.toggleHamburger()
      this.closeSearch()
    }
  },
  computed: {
    getNavClass () {
      if (this.ignoredUrls.includes(this.$route.path)) {
        return null
      }
      return (this.$route.name === 'site-slug' || this.$route.name === 'hall-of-fame' || this.$route.name === 'hall-of-fame-slug') ? 'fake-active' : null
    },
    getMobileNavBarTitle () {
      if (this.$route.path === '/site/careers') {
        return 'Careers'
      }

      if (this.ignoredUrls.includes(this.$route.path)) {
        return null
      }

      let idx = this.$route.path.indexOf('/', 1)
      if (idx === -1) {
        idx = this.$route.path.length
      }

      const root = this.$route.path.substring(0, idx)

      if (root === '/' || root === '/site' || root === '/hall-of-fame') {
        return 'Welcome'
      }
      if (root === '/products') {
        return 'Products'
      }
      if (root === '/downloads') {
        return 'Downloads'
      }
      if (root === '/training') {
        return 'Training'
      }
      if (root === '/faq') {
        return 'FAQ'
      }
      if (root === '/dealers') {
        return 'Dealers'
      }
      return null
    }
  }
}

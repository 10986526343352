//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  data () {
    return {
      socialMediaLinks: [
        {
          icon: 'facebook',
          link: 'https://www.facebook.com/motec.global/'
        },
        {
          icon: 'youtube',
          link: 'https://www.youtube.com/user/MoTeCAustralia'
        },
        {
          icon: 'instagram',
          link: 'https://www.instagram.com/motec_global'
        },
        {
          icon: 'linkedin',
          link: 'https://www.linkedin.com/company/motec-global'
        }
      ],
      infoLinks: [
        {
          icon: 'information',
          link: '/site/information'
        },
        {
          icon: 'phone-in-talk',
          link: '/contact'
        }
      ]
    }
  },
  methods: {
    isRed (link) {
      return (link === this.$route.path)
    }
  }
}


//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: String
  },
  computed: {
    link () {
      return (this.title === 'Welcome') ? '/' : '/' + this.title.toLowerCase()
    }
  }
}

export const state = () => ({
  store: {}
})

export const mutations = {
  setDealers (state, dealers) {
    state.store = dealers
  },
  setCountry (state, country) {
    if (!state.store) {
      state.store = {}
    }
    state.store.country = country
    if (process.browser) {
      localStorage.setItem('dealers', JSON.stringify(state.store))
    }
  },
  setState (state, countryState) {
    if (!state.store) {
      state.store = {}
    }
    state.store.countryState = countryState
    if (process.browser) {
      localStorage.setItem('dealers', JSON.stringify(state.store))
    }
  }
}

export const getters = {
  country: (state) => {
    return state.store ? state.store.country : null
  },
  countryState: (state) => {
    return state.store ? state.store.countryState : null
  }
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04f7a224 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _528c5007 = () => interopDefault(import('../pages/compare.vue' /* webpackChunkName: "pages/compare" */))
const _6d9021c2 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _53ed679f = () => interopDefault(import('../pages/dealers/index.vue' /* webpackChunkName: "pages/dealers/index" */))
const _eae01160 = () => interopDefault(import('../pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _371241e4 = () => interopDefault(import('../pages/ecuwizard.vue' /* webpackChunkName: "pages/ecuwizard" */))
const _8df2618a = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _13011074 = () => interopDefault(import('../pages/hall-of-fame/index.vue' /* webpackChunkName: "pages/hall-of-fame/index" */))
const _38675135 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _70c40d27 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _2b5d10e9 = () => interopDefault(import('../pages/sandbox.vue' /* webpackChunkName: "pages/sandbox" */))
const _0e43540e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _11c5a5aa = () => interopDefault(import('../pages/training/index.vue' /* webpackChunkName: "pages/training/index" */))
const _d79a030c = () => interopDefault(import('../pages/version.vue' /* webpackChunkName: "pages/version" */))
const _3d5fbb92 = () => interopDefault(import('../pages/downloads/special.vue' /* webpackChunkName: "pages/downloads/special" */))
const _09ffe222 = () => interopDefault(import('../pages/products/engine-management/targeted-range/atv.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/atv" */))
const _481a0c72 = () => interopDefault(import('../pages/products/engine-management/targeted-range/bike.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/bike" */))
const _06f62ae2 = () => interopDefault(import('../pages/products/engine-management/targeted-range/category.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/category" */))
const _c49638da = () => interopDefault(import('../pages/products/engine-management/targeted-range/marine.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/marine" */))
const _1e38ea4f = () => interopDefault(import('../pages/products/engine-management/targeted-range/other.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/other" */))
const _5dd84894 = () => interopDefault(import('../pages/products/engine-management/targeted-range/snowmobile.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/snowmobile" */))
const _245efeab = () => interopDefault(import('../pages/products/engine-management/targeted-range/vehicle.vue' /* webpackChunkName: "pages/products/engine-management/targeted-range/vehicle" */))
const _7c2aeb52 = () => interopDefault(import('../pages/products/engine-management/targeted-package/_slug.vue' /* webpackChunkName: "pages/products/engine-management/targeted-package/_slug" */))
const _e90cba34 = () => interopDefault(import('../pages/products/category/_slug.vue' /* webpackChunkName: "pages/products/category/_slug" */))
const _c1fea75e = () => interopDefault(import('../pages/products/category/_catslug/_slug.vue' /* webpackChunkName: "pages/products/category/_catslug/_slug" */))
const _07afba12 = () => interopDefault(import('../pages/dealer/_id.vue' /* webpackChunkName: "pages/dealer/_id" */))
const _2ce56463 = () => interopDefault(import('../pages/faq/_id.vue' /* webpackChunkName: "pages/faq/_id" */))
const _166e5304 = () => interopDefault(import('../pages/hall-of-fame/_slug.vue' /* webpackChunkName: "pages/hall-of-fame/_slug" */))
const _36b0afed = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _513c4910 = () => interopDefault(import('../pages/site/_slug.vue' /* webpackChunkName: "pages/site/_slug" */))
const _1532e83a = () => interopDefault(import('../pages/training/_slug.vue' /* webpackChunkName: "pages/training/_slug" */))
const _78cdb7f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _04f7a224,
    name: "cart"
  }, {
    path: "/compare",
    component: _528c5007,
    name: "compare"
  }, {
    path: "/contact",
    component: _6d9021c2,
    name: "contact"
  }, {
    path: "/dealers",
    component: _53ed679f,
    name: "dealers"
  }, {
    path: "/downloads",
    component: _eae01160,
    name: "downloads"
  }, {
    path: "/ecuwizard",
    component: _371241e4,
    name: "ecuwizard"
  }, {
    path: "/faq",
    component: _8df2618a,
    name: "faq"
  }, {
    path: "/hall-of-fame",
    component: _13011074,
    name: "hall-of-fame"
  }, {
    path: "/products",
    component: _38675135,
    name: "products"
  }, {
    path: "/reset-password",
    component: _70c40d27,
    name: "reset-password"
  }, {
    path: "/sandbox",
    component: _2b5d10e9,
    name: "sandbox"
  }, {
    path: "/search",
    component: _0e43540e,
    name: "search"
  }, {
    path: "/training",
    component: _11c5a5aa,
    name: "training"
  }, {
    path: "/version",
    component: _d79a030c,
    name: "version"
  }, {
    path: "/downloads/special",
    component: _3d5fbb92,
    name: "downloads-special"
  }, {
    path: "/products/engine-management/targeted-range/atv",
    component: _09ffe222,
    name: "products-engine-management-targeted-range-atv"
  }, {
    path: "/products/engine-management/targeted-range/bike",
    component: _481a0c72,
    name: "products-engine-management-targeted-range-bike"
  }, {
    path: "/products/engine-management/targeted-range/category",
    component: _06f62ae2,
    name: "products-engine-management-targeted-range-category"
  }, {
    path: "/products/engine-management/targeted-range/marine",
    component: _c49638da,
    name: "products-engine-management-targeted-range-marine"
  }, {
    path: "/products/engine-management/targeted-range/other",
    component: _1e38ea4f,
    name: "products-engine-management-targeted-range-other"
  }, {
    path: "/products/engine-management/targeted-range/snowmobile",
    component: _5dd84894,
    name: "products-engine-management-targeted-range-snowmobile"
  }, {
    path: "/products/engine-management/targeted-range/vehicle",
    component: _245efeab,
    name: "products-engine-management-targeted-range-vehicle"
  }, {
    path: "/products/engine-management/targeted-package/:slug?",
    component: _7c2aeb52,
    name: "products-engine-management-targeted-package-slug"
  }, {
    path: "/products/category/:slug?",
    component: _e90cba34,
    name: "products-category-slug"
  }, {
    path: "/products/category/:catslug?/:slug?",
    component: _c1fea75e,
    name: "products-category-catslug-slug"
  }, {
    path: "/dealer/:id?",
    component: _07afba12,
    name: "dealer-id"
  }, {
    path: "/faq/:id",
    component: _2ce56463,
    name: "faq-id"
  }, {
    path: "/hall-of-fame/:slug?",
    component: _166e5304,
    name: "hall-of-fame-slug"
  }, {
    path: "/products/:slug",
    component: _36b0afed,
    name: "products-slug"
  }, {
    path: "/site/:slug?",
    component: _513c4910,
    name: "site-slug"
  }, {
    path: "/training/:slug",
    component: _1532e83a,
    name: "training-slug"
  }, {
    path: "/",
    component: _78cdb7f4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

export const state = () => ({
  phrase: '',
  searchHistory: []
})

export const mutations = {
  setPhrase (state, phrase) {
    state.phrase = phrase
  },
  setPhraseUpdateHistory (state, phrase) {
    state.phrase = phrase
    if (phrase && phrase.trim() && state.searchHistory.unshift({ phrase }) > 5) {
      state.searchHistory.pop()
    }
  }
}

export const getters = {
  phrase (state) {
    return state.phrase
  },
  getHistory (state) {
    return state.searchHistory
  }
}

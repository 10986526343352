//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    statusCode: {
      type: Number,
      default: 404
    },
    message: String
  }
}

export const state = () => ({
  accordionIndex: -1
})

export const mutations = {
  setAccordionIndex (state, index) {
    state.accordionIndex = index
  }
}

export const getters = {
  accordionIndex: (state) => {
    return state.accordionIndex
  }
}
